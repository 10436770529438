import React from 'react'
import styled from 'styled-components'
import colors from 'styles/colors'
import Icon from '../Icon'
import * as routes from 'config/routes'

import { Text } from 'components/Landings/Explained'
import { Link } from '@reach/router'
import LineBreak from 'components/Landings/LineBreak'

export const FormattedText = styled(Text)`
  text-align: left;
  ${props => {
    switch (props.size) {
      case 'large':
        return `
          margin-top: 0;
          line-height: 1.2em;
          font-size: 30px;
          margin-bottom: 15px;
          @media (min-width: 320px) {
            font-size: calc(30px + 30 * ((100vw - 320px) / 1580));
            margin-bottom: calc(15px + 25 * ((100vh - 480px) / 900));
          }
          @media (min-width: 768px) {
            font-size: 5.1vmin;
            margin-bottom: 1.8vh;
          }`
      case 'medium':
        return `
          font-size: 18px;
          line-height: 1.2em;
          margin-top: 0;
          margin-bottom: 15px;
          @media (min-width: 320px) {
            font-size: calc(18px + 25 * ((100vw - 320px) / 1580));
            margin-bottom: calc(15px + 20 * ((100vh - 480px) / 420));
          }
          @media (min-width: 768px) {
            line-height: 5vmin;
            font-size: 2.5vmin;
            margin-bottom: 1.8vh;
          }`
      default:
        return `
          font-size: 16px;
          line-height: 1.25;
          @media (min-width: 320px) {
            font-size: calc(16px + 10 * ((100vw - 320px) / 1600));
          }
          @media (min-width: 768px) {
            font-size: 2vmin;
          }`
    }
  }};
`
export const TextInfo = styled(FormattedText)`
  margin: 5px;
  margin-left: 0px;
  text-align: left;
`
const Anchor = styled.a`
  text-decoration: none;
  text-align: left;
`
const ColumnInfo = styled.div`
  @media (max-width: 600px) {
    margin-bottom: 25px;
    margin-left: 2rem;
  }
`

const LinkText = styled(Link)`
  text-decoration: none;
  text-align: left;
`
const RowFooter = styled.div`
  align-items: baseline;
  width: 50%;
  justify-content: space-between;

  @media only screen and (max-width: 992px) {
    margin-left: 2rem;
    margin-right: 2rem;
  }

  @media (max-width: 600px) {
    justify-content: left;
    flex-direction: column;
    align-items: start;
    width: 100%;
    margin-left: ${({ margin }) => margin || 0.5}rem;
    margin-right: 0.5rem;
  }

  @media only screen and (min-width: 992px) {
    margin-left: 5rem;
    margin-right: 5rem;
  }
`
const Container = styled.div`
  align-items: center;
  // @media (max-width: 600px) {
  //   display: none;
  // }
`

const LineBreakFooter = styled(LineBreak)`
  width: 100% !important;
  margin: 2vh auto;
`

const FooterText = styled.p`
  @media (max-width: 600px) {
    margin-left: 2rem;
  }
`

const Footer = () => {
  return (
    <Container className='flex justify-center flex-column mt5-ns mt-4'>
      <RowFooter className='flex flex-wrap'>
        <ColumnInfo className='justify-start tl mt3'>
          <FormattedText weight={400} size='medium' heading>
            Adventure Machine
          </FormattedText>

          <ExternalLink
            weight={400}
            link='https://play.google.com/store/apps/details?id=com.staycircles.am.android'
          >
            Google Play Store (Android)
          </ExternalLink>

          <ExternalLink
            weight={400}
            link='https://itunes.apple.com/us/app/adventure-machine/id1412862317?ls=1&mt=8'
          >
            Apple App Store (iOS)
          </ExternalLink>

          <InappLink weight={400} link={routes.EVENTS}>
            Event Flight Watcher <NewBadge />
          </InappLink>
        </ColumnInfo>

        <ColumnInfo className='justify-start tl mt3'>
          <FormattedText weight={400} size='medium' heading>
            StayCircles
          </FormattedText>

          <InappLink weight={400} link={routes.LOGIN}>
            Login
          </InappLink>

          <InappLink weight={400} link={routes.SIGNUP}>
            Create Account
          </InappLink>
        </ColumnInfo>

        <ColumnInfo className='justify-start tl mt3'>
          <FormattedText weight={400} size='medium'>
            <IconLink
              link='https://www.facebook.com/AdventureMachineSC'
              name='facebook-square'
            />
            <IconLink
              link='https://twitter.com/adventuremachSC'
              name='twitter-square'
            />
            <IconLink
              link='https://instagram.com/adventuremachinesc'
              name='instagram'
            />
          </FormattedText>

          <InappLink weight={400} link={routes.TERMS}>
            Terms
          </InappLink>

          <InappLink weight={400} link={routes.PRIVACY}>
            Privacy
          </InappLink>
        </ColumnInfo>
      </RowFooter>

      <RowFooter className='flex mh5-ns flex-wrap' margin={0}>
        <LineBreakFooter />
        <FooterText className='f5'>
          © StayCircles, Inc. All rights reserved.
        </FooterText>
      </RowFooter>
    </Container>
  )
}

export const IconLink = ({
  link,
  name,
  color = colors.lightBlack,
  height = 40
}) => (
  <a href={link} target='_blank' rel='noreferrer'>
    <Icon name={name} className='pointer pr2' color={color} height={height} />
  </a>
)

const ExternalLink = ({ link, text, weight = 400, children }) => (
  <Anchor href={link} target='_blank' rel='noreferrer'>
    <TextInfo weight={400}>{children}</TextInfo>
  </Anchor>
)

const InappLink = ({ link, text, weight = 400, children }) => (
  <LinkText to={link}>
    <TextInfo weight={400}>{children}</TextInfo>
  </LinkText>
)

const NewBadge = styled.span.attrs({
  children: 'NEW'
})`
  display: inline-block;
  background-color: ${colors.lightBlueI};
  color: ${colors.darkBlueII};
  padding: 4px 8px;
  font-size: 8px;
  line-height: 10px;
  margin-left: 5px;
  border-radius: 4px;

  @media (max-width: 540px) {
    padding: 4px 8px;
  }
`

export default Footer
