import React from 'react'

import styled from 'styled-components'

import colors from 'styles/colors'

import { Container, Row, Column, Text } from 'components/Landings/Explained'

const AMExplained = ({ children }) => (
  <Container className='w-80-m w-50-l'>
    <Text size='medium' italic>
      “But.. travel is <Weight weight={900}>super expensive.</Weight>”
    </Text>

    <Text weight={900} size='large'>
      WE GET IT
    </Text>

    <Text weight={400} size='medium'>
      There’s a better way.
      <br />
      The “dream trip” to Paris is possible.
    </Text>

    <ComparisonTable>
      <tbody>
        <tr style={{ backgroundColor: 'rgba(0, 0, 0, 0.02)' }}>
          <td colSpan='5' style={{ paddingTop: '3.5vh' }}>
            <Text italic size='medium'>
              <Weight weight={300}>The trip: A week in Paris.</Weight>
            </Text>
          </td>
        </tr>

        <tr style={{ backgroundColor: 'rgba(252, 97, 72, 0.075)' }}>
          <td>
            <Text align='right'>Stay in a normal hotel. </Text>
          </td>

          <td>
            <Icon src='https://res.cloudinary.com/staycircles/image/upload/f_auto,q_auto/assets/am_landing/am_landing_hotel' />
          </td>

          <td rowSpan={2} style={{ padding: '0px 0px 0px 1rem', minWidth: 60 }}>
            <Text>
              $700
              <br />+<br />
              $700
            </Text>
          </td>

          <td rowSpan={2} className='ph3'>
            <Text size='medium' color={colors.red}>
              $1,400
            </Text>
          </td>

          <td rowSpan={2} className='ph3'>
            <Text size='medium' weight={400}>
              OLD
            </Text>
          </td>
        </tr>

        <tr style={{ backgroundColor: 'rgba(252, 97, 72, 0.075)' }}>
          <td>
            <Text align='right'>Fly from New York for a normal price. </Text>
          </td>

          <td>
            <Icon src='https://res.cloudinary.com/staycircles/image/upload/f_auto,q_auto/assets/am_landing/am_landing_airplane' />
          </td>
        </tr>

        <tr
          style={{
            backgroundColor: 'rgba(83, 160, 255, 0.15)rgba(252, 97, 72, 0.075)'
          }}
        >
          <td>
            <Text align='right'>Stay with a friend. </Text>
          </td>

          <td>
            <Icon src='https://res.cloudinary.com/staycircles/image/upload/f_auto,q_auto/assets/am_landing/am_landing_staypal' />
          </td>

          <td rowSpan={2} style={{ padding: '0px 0px 0px 1rem', minWidth: 60 }}>
            <Text>
              $0
              <br />+<br />
              $305
            </Text>
          </td>

          <td rowSpan={2} className='ph3'>
            <Text size='medium' color={colors.blue}>
              $305
            </Text>
          </td>

          <td rowSpan={2} className='ph3'>
            <img
              src='https://res.cloudinary.com/staycircles/image/upload/f_auto,q_auto/assets/branding/am_sc_logo_combined'
              className='db center'
            />
          </td>
        </tr>

        <tr style={{ backgroundColor: 'rgba(83, 160, 255, 0.15)' }}>
          <td>
            <Text align='right'>
              Fly from New York for a Adventure machine price.
            </Text>
          </td>

          <td>
            <Icon src='https://res.cloudinary.com/staycircles/image/upload/f_auto,q_auto/assets/am_landing/am_landing_airplane' />
          </td>
        </tr>

        <tr
          style={{ backgroundColor: 'rgba(0, 0, 0, 0.02)' }}
          className='relative w-100 dn dt-row-ns'
        >
          <td className='w-40'>
            <Text align='right'>
              <Weight
                weight={600}
                className='db '
                style={{ marginBottom: '2rem' }}
              >
                Epic deals
              </Weight>
            </Text>

            <Text align='right' className='lh-copy'>
              From mistake fares, to secret discounts - we make sure you get
              alerted asap.
            </Text>
          </td>

          <td colSpan={2} className='w-20' style={{ padding: '1rem 0' }}>
            <img
              className='db center'
              src='https://res.cloudinary.com/staycircles/image/upload/f_auto,q_auto/assets/am_deal_details_iphone'
            />
          </td>

          <td colSpan={2} className='w-40' style={{ padding: '1rem' }}>
            <Text align='left'>
              <Weight
                weight={600}
                className='db'
                style={{ marginBottom: '2rem' }}
              >
                Stay with Friends
              </Weight>
            </Text>

            <Text align='left' className='lh-copy'>
              See all your friends and friends-of-friends in the destination
            </Text>
          </td>
        </tr>
      </tbody>
    </ComparisonTable>

    {children}

    <Row>
      <Column>
        <Text weight={900} size='medium'>
          Epic deals.
        </Text>

        <Text weight={400}>
          From mistake fares, to secret discounts - we make sure you get alerted
          asap.
        </Text>
      </Column>

      <Column>
        <Phone src='https://res.cloudinary.com/staycircles/image/upload/f_auto,q_auto/assets/am_deal_details_iphone' />
      </Column>
    </Row>

    <Row>
      <Column className='dn db-ns'>
        <Phone src='https://res.cloudinary.com/staycircles/image/upload/f_auto,q_auto/assets/am_deals_feed_iphone' />
      </Column>

      <Column>
        <Text weight={900} size='medium'>
          Friends everywhere.
        </Text>

        <Text weight={400}>
          See your friends (and friends-of-friends) in the destination, so you
          can easily see and stay with each other when traveling.
        </Text>
      </Column>

      <Column className='db dn-ns'>
        <Phone src='https://res.cloudinary.com/staycircles/image/upload/f_auto,q_auto/assets/am_deals_feed_iphone' />
      </Column>
    </Row>

    <Row>
      <Column>
        <Text weight={900} size='medium'>
          The world is yours.
        </Text>

        <Text weight={400}>
          Your map with friends and friends-of-friends makes it easy to see and
          book stays!
        </Text>
      </Column>

      <Column>
        <Phone src='https://res.cloudinary.com/staycircles/image/upload/f_auto,q_auto/assets/sc_map_iphone' />
      </Column>
    </Row>
  </Container>
)

const ComparisonTable = styled.table`
  border: 1px solid #ccc;
  border-radius: 12px;
  border-collapse: collapse;

  td:first-child {
    padding: 1rem;
  }

  td {
    border: none;
  }

  p {
    font-weight: 400;
    margin-bottom: 0;
  }

  @media (max-width: 480px) {
    td:nth-child(2),
    td:nth-child(4) {
      display: none;
    }
  }
`

const Icon = styled.img`
  width: 64px;
  margin: 0 auto;
  padding: 8px;
`

export const Phone = styled.img.attrs({
  className: 'db center'
})`
  height: 400px;
  width: 202px;
`

const Weight = styled.span`
  font-weight: ${props => props.weight || 600};
`

export default AMExplained
