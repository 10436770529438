import styled, { css } from 'styled-components'

import colors from 'styles/colors'

const noTop = css`
  ${props => props.noTop && `margin-top: 0;`};
`

const noBottom = css`
  ${props => props.noBottom && `margin-bottom: 0;`};
`

const halfTop = css`
  ${props => props.halfTop && `margin-top: 6.6vh;`};
`

const halfBottom = css`
  ${props => props.halfBottom && `margin-bottom: 6.6vh;`};
`

const LineBreak = styled.div`
  width: 90vw;
  height: 1px;
  margin: 13.2vh auto;
  background-color: ${colors.blackRGBA(0.2)};

  ${noTop}
  ${noBottom}

  ${halfTop}
  ${halfBottom}

  @media (min-width: 758px) {
    width: 33vw;
  }
`

export default LineBreak
