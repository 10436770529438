import styled, { css } from 'styled-components'
import colors from 'styles/colors'

const marginBottom = '6.6vh'
const marginBottomMedium = 'calc(15px + 45 * ((100vh - 480px) / 900))'
const marginBottomSmall = '15px'

const whiteBg = css`
  background: #fff;
`

const landscapeBg = css`
  background-image: url('https://res.cloudinary.com/staycircles/image/upload/v1572879855/assets/landing-sc-bg.jpg');
  background-repeat: no-repeat;
  background-size: cover;
`

export const HeroContainer = styled.div`
  height: 100vh;

  ${props => (props.hideBackground ? whiteBg : landscapeBg)};
`

export const MiddleContainer = styled.div`
  height: 22.2vh;
  margin-top: 0;
  margin-bottom: 20px;

  @media (max-width: 767px) {
    height: initial;
    margin-bottom: calc(20px + 20 * ((100vh - 480px) / 420));
  }

  @media (min-width: 768px) {
    margin-bottom: 4.4vh;
  }
`

const headlineFontSize = '8.1vmin'
const headlineFontSizeMedium = 'calc(30px + 34 * ((100vw - 320px) / 1580))'
const headlineFontSizeSmall = '30px'

export const Headline = styled.h1`
  margin-top: 0;
  line-height: 1.2em;
  font-weight: ${props => props.fw || 500};
  font-size: ${headlineFontSizeSmall};
  margin-bottom: ${marginBottomSmall};

  @media (min-width: 320px) {
    font-size: ${headlineFontSizeMedium};
    margin-bottom: ${marginBottomMedium};
  }

  @media (min-width: 768px) {
    font-size: ${headlineFontSize};
    margin-bottom: ${marginBottom};
  }
`
export const LandingBGHeadline = styled.h1`
  color: ${colors.white};
  padding-right: 8rem;
  text-align: left;

  @media (max-width: 768px) {
    font-size: ${headlineFontSizeSmall};
    text-align: center;
    padding-right: 0rem;
  }

  @media (min-width: 768px) {
    font-size: ${headlineFontSize};
  }

  @media only screen and (min-width: 992px) {
    font-size: ${headlineFontSize};
  }
`

export const SectionHeadline = styled.h1`
  font-size: 5vmin;
  font-weight: 600;
  margin-top: 0;
  margin-bottom: ${marginBottom};

  &[class~='mt0'] {
    margin-top: 0;
  }

  &[class~='mb0'] {
    margin-bottom: 0;
  }

  @media (max-width: 470px) {
    font-size: calc(30px + 34 * ((100vw - 320px) / 1580));
  }
`

const subheadFontSize = '4vmin'
const subheadMarginBottom = '4.4vh'
const subheadFontSizeMedium = 'calc(18px + 6 * ((100vw - 320px) / 1580))'
const subheadMarginBottomMedium = 'calc(20px + 20 * ((100vh - 480px) / 420))'
const subheadFontSizeSmall = '18px'
const subheadMarginBottomSmall = '20px'

export const SubHeadline = styled.p`
  font-size: ${subheadFontSizeSmall};
  line-height: 1.2em;
  font-weight: 300;
  margin-top: 0;
  margin-bottom: ${subheadMarginBottomSmall};

  @media (min-width: 320px) {
    font-size: ${subheadFontSizeMedium};
    margin-bottom: ${subheadMarginBottomMedium};
  }

  @media (min-width: 768px) {
    line-height: 4.8vmin;
    font-size: ${subheadFontSize};
    margin-bottom: ${subheadMarginBottom};
  }

  &[class~='mt0'] {
    margin-top: 0;
  }

  &[class~='mb0'] {
    margin-bottom: 0;
  }
`
