import React from 'react'

import get from 'lodash/get'
import { Match } from '@reach/router'
import { Link } from 'gatsby'

import * as routes from 'config/routes'
import queryString from 'utils/parse-querystring'
import StateMachine from 'utils/StateMachine'
import { getFullName, getUserPicture } from 'utils/profile-info'
import SignUpStoreProvider from 'pages/signup/_store'

import styled from 'styled-components'

import colors from 'styles/colors'

import Modal from 'components/Modal'
import Icon from 'components/Icon'
import Button from 'components/Button'
import VideoView from 'components/Landings/VideoView'

const statechart = {
  key: 'invitation-modal',
  initial: 'hide',
  states: {
    hide: {
      on: { TOGGLE: 'show' }
    },
    show: {
      on: { TOGGLE: 'hide' },
      onEntry: 'showModal'
    }
  }
}

let initialized = false

const InvitationBadge = ({ showModalFirst = true }) => (
  <Match path='/?invitation_code=*'>
    {({ location }) => {
      const query = queryString(location.search)
      const invitationToken = get(query, 'invitation_code')

      if (invitationToken) {
        return (
          <StateMachine statechart={statechart} renderOnAction='showModal'>
            {(state, visible) => {
              if (showModalFirst && !initialized) {
                state.transition('TOGGLE')
                initialized = true
              }

              return (
                <SignUpStoreProvider invitationToken={invitationToken}>
                  {store => {
                    const staypal = get(store.state.data, 'invitedBy')
                    return (
                      <React.Fragment>
                        {!visible && <Badge staypal={staypal} />}

                        <Modal
                          isOpen={visible}
                          onRequestClose={() => state.transition('TOGGLE')}
                          contentLabel='Invite message'
                        >
                          <Invitation
                            staypal={staypal}
                            close={() => state.transition('TOGGLE')}
                          />
                        </Modal>
                      </React.Fragment>
                    )
                  }}
                </SignUpStoreProvider>
              )
            }}
          </StateMachine>
        )
      }

      return null
    }}
  </Match>
)

const Invitation = ({ staypal, close }) => (
  <div className='center flex flex-column w-100'>
    <Logo />

    <Picture
      src={getUserPicture(staypal, { width: 120 })}
      className='center mv4'
      size={120}
    />

    <p className='f3 lh-copy mb0 black tc mb3 mb4-ns'>
      {getFullName(staypal)} has sent you this private invitation to StayCircles
      beta.
      <br />
      <br />
      Welcome!
    </p>

    <hr />

    <div className='flex flex-row justify-center items-center'>
      <VideoView videoUrl='https://www.youtube.com/embed/ycnS2E9GnIw?rel=0&autoplay=1'>
        {({ onClick }) => (
          <Button
            primary
            bgColor={colors.red}
            bgColorHover={colors.darkRed}
            className='mr3'
            onClick={onClick}
          >
            Intro Video
            <Icon name='yt-play' height={14} className='ml2' />
          </Button>
        )}
      </VideoView>

      <Button
        primary
        bgColor={colors.blue}
        bgColorHover={colors.darkBlue}
        className='flex flex-row justify-center items-center'
        onClick={close}
      >
        Learn More
        <Icon name='caret' height={10} className='ml2' />
      </Button>
    </div>
  </div>
)

const Logo = styled(Icon).attrs({ name: 'sc-logo' })`
  color: #333;
  height: 60px;
  margin: 0 auto 1rem;
`

const Badge = ({ staypal }) => (
  <BadgeWrapper
    className='white br3 fixed bottom-2 left-2 shadow-4 pa3 tc pointer'
    to={routes.preserveQuery(routes.CREATE_ACCOUNT)}
  >
    <LogoWhite />

    <p className='mb2'>
      <span className='fw7'>Invitation</span> from{' '}
    </p>

    <Picture src={getUserPicture(staypal)} className='dn db-ns center mb2' />

    <p className='mb0'>{getFullName(staypal) || 'Loading...'}</p>
  </BadgeWrapper>
)

const BadgeWrapper = styled(Link)`
  width: 140px;
  background-color: ${colors.red};
  text-decoration: none;
`

const LogoWhite = styled(Icon).attrs({ name: 'sc-logo-mono' })`
  color: #fff;
  width: 108px;
  margin-bottom: 10px;
`

const Picture = styled.img`
  width: ${props => props.size || 60}px;
  height: ${props => props.size || 60}px;
`

export default InvitationBadge
