import React, { Component, Fragment } from 'react'

import { navigate } from 'gatsby'

import * as routes from 'config/routes'

import styled from 'styled-components'

import colors from 'styles/colors'

import ModalComponent from 'components/Modal'
import Icon from 'components/Icon'
import Button from 'components/Button'

class VideoView extends Component {
  state = { showModal: false }

  toggle = () => {
    this.setState(prevState => ({
      showModal: !prevState.showModal
    }))
  }

  renderButtons () {
    const { createAccount } = this.props

    if (createAccount) {
      return (
        <Fragment>
          <Button
            color={colors.white}
            colorHover={colors.offwhite}
            bgColor={colors.red}
            bgColorHover={colors.darkRed}
            onClick={() => {
              this.toggle()
              navigate(routes.SIGNUP)
            }}
            className='mr3 mr4-ns'
          >
            Create Account
          </Button>

          <Button
            color={colors.white}
            colorHover={colors.offwhite}
            bgColor={colors.blue}
            bgColorHover={colors.darkBlue}
            onClick={this.toggle}
            className='flex flex-row justify-center items-center'
          >
            Learn More <Icon name='caret' height={12} className='ml2' />
          </Button>
        </Fragment>
      )
    }

    return (
      <Button
        link
        onClick={this.toggle}
        color={colors.darkBlue}
        colorHover={colors.blackRGBA(0.5)}
        className='flex flex-row justify-center items-center'
        style={{ fontWeight: 600 }}
      >
        <Icon name='close' height={10} className='mr2' />
        Close
      </Button>
    )
  }

  render () {
    const {
      headline,
      subheadline,
      personImgSrc,
      children,
      className,
      videoUrl
    } = this.props

    return (
      <Fragment>
        {!children && (
          <Wrapper
            onClick={this.toggle}
            title={headline}
            className={`flex flex-row justify-around justify-center-ns items-center ${className ||
              ''}`}
          >
            <Container className='flex flex-column justify-center items-center'>
              <span>{headline}</span>

              <PlayIcon color='#2D89F8' />

              <span>{subheadline}</span>
            </Container>

            <PersonImg src={personImgSrc} alt={headline} />
          </Wrapper>
        )}

        {children && children({ onClick: this.toggle })}

        <Modal
          isOpen={this.state.showModal}
          onRequestClose={this.toggle}
          contentLabel='Video'
        >
          <div className='center flex flex-column w-100'>
            <div className='flex w-100'>
              <iframe
                title='Video'
                width='853'
                height='480'
                src={videoUrl}
                frameBorder='0'
                allowFullScreen
                // ref={r => { this.video = r }}
              />
            </div>

            <div className='flex flex-row justify-center items-center mt1 mt4-ns'>
              {this.renderButtons()}
            </div>
          </div>
        </Modal>
      </Fragment>
    )
  }
}

const RawBtn = styled.button.attrs({ type: 'button' })`
  background: transparent;
  border: none;
  outline: none;
  cursor: pointer;
`

const Wrapper = styled(RawBtn)`
  color: ${colors.darkBlue};

  @media (max-width: 480px) {
    width: 230px;
    padding: 20px;
    border: 1px solid ${colors.blueRGBA(0.25)};
    border-radius: 10px;
  }

  &:hover {
    color: ${colors.darkerBlue};

    svg {
      fill: ${colors.darkerBlue};
    }
  }
`

const Container = styled.div`
  font-size: 12px;
  line-height: 1.2em;
  font-weight: 300;
  margin-right: 0.84vw;

  @media (min-width: 320px) {
    font-size: calc(16px + 12 * ((100vw - 320px) / 1580));
  }

  @media (min-width: 768px) {
    font-size: 2.6vmin;
    line-height: 3.36vmin;
  }
`

const PlayIcon = styled(Icon).attrs({ name: 'yt-play' })`
  width: 30px;
  min-width: 30px;
  height: auto;

  margin: 8px 0;
  fill: ${colors.darkBlue};
  transition: all 0.25s ease;

  @media (min-width: 320px) {
    width: calc(30px + 44 * ((100vw - 320px) / 1580));
  }

  @media (min-width: 768px) {
    width: 3.9vw;
    height: 5.8vh;
    margin: 15px 0;
  }
`

const PersonImg = styled.img`
  width: 30px;
  min-width: 30px;
  min-height: 84px;

  @media (min-width: 320px) {
    width: calc(45px + 48 * ((100vw - 320px) / 1580));
  }

  @media (min-width: 450px) {
    width: calc(45px + 60 * ((100vw - 450px) / 1580));
  }

  @media (min-width: 768px) {
    width: 60px;
  }
`

const Modal = styled(ModalComponent)`
  max-width: 870px;

  iframe {
    margin: 0;
  }

  @media (max-width: 767px) {
    padding: 0.5rem;

    iframe {
      max-height: 300px;
    }
  }
`

export default VideoView
